<template>
  <div class="page">
    <!-- <div class="banner">
      <img src="./img/banner.png" alt="" @click="toPath('lawServiceGuide')" />
    </div> -->
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toRouteTitle(item)"
        >
          <div class="item-left">
            <img :src="item.topicPicture" />
          </div>
          <div class="item-right">
            <div class="title">
              {{ item.topicTitle || "" }}
            </div>
            <div class="date">{{ item.createTime || "" }}</div>
            <!-- <div class="info">
              <img src="./img/watch.png" alt="" />
              <div>{{ item.readCount }}</div>
              <img src="./img/message.png" alt="" />
              <div>{{ item.commentCount }}</div>
            </div> -->
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getTopicListURL } from "./api.js";
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { handleImg } from "@/utils/utils";
import { getCount } from "@/utils/common.js";
export default {
  name: "legalAdvice",
  data() {
    return {
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 20,
      type: null,
      titleName: "",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    const { type, titleName } = this.$route.query;
    this.type = type;
    this.titleName = titleName;
  },
  methods: {
    toRouteTitle(item) {
      if (item.editType == 0) {
        let params = {
          id: item.id,
          titleName: this.titleName,
        };
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/legalAdviceDetail/legalAdviceTitle?params=${JSON.stringify(
              params
            )}`,
          });
        }
      } else if (item.editType == 1) {
        getCount(item);
        window.location.href = item.externalUrl;
        // wx.miniProgram.navigateTo({
        //   url: `/pages/webview/index?url=${item.externalUrl}`,
        // });
      }
    },
    toPath(path) {
      this.$router.push({
        name: path,
      });
    },
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            // sendPointFlag: 1, // 传1 表示调该接口时后端会自行调获取组合任务积分的接口 不传此参数则不掉
            tenantId: this.tenantId,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: this.type,
            curPage: this.curPage,
            pageSize: this.pageSize,
            sortField: "NEW", // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.topicPicture) {
              item.topicPicture = handleImg(256, 256, item.topicPicture);
            }
          });
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background: #fafafa;
  padding-top: 30px;

  .banner {
    width: 100%;
    height: 394px;
    padding: 30px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .list {
    padding: 0 30px;
    box-sizing: border-box;
    .item {
      height: 256px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      margin-bottom: 30px;
      display: flex;
      overflow: hidden;
      .item-left {
        width: 256px;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .item-right {
        flex: 1;
        padding: 26px 58px 0 20px;
        box-sizing: border-box;
        position: relative;
        .title {
          width: 352px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          font-size: 32px;
          font-weight: 600;
          color: #333333;
          line-height: 44px;
        }
        .date {
          font-size: 26px;
          color: rgba(0, 0, 0, 0.25);
          position: absolute;
          bottom: 68px;
          left: 20px;
        }
        .info {
          position: absolute;
          bottom: 24px;
          left: 20px;
          display: flex;
          align-items: center;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.25);
          img {
            width: 32px;
            height: 34px;
            margin-right: 8px;
          }
          div {
            margin-right: 32px;
          }
        }
      }
    }
  }
}
</style>
